import React, { useContext } from 'react';
import EditModeContext from './EditModeContext'; // Update this path according to your project structure

const EditButton = () => {
  const { editMode, toggleEditMode } = useContext(EditModeContext);

  return (
    <button onClick={() => {toggleEditMode()}} 
      className="effect-button"
      style={{
      margin: '50px', 
      width: '125px', 
      height: '85px',
      position: 'fixed',
      right: '0',
      bottom: '0'
      }}>
      {editMode ? 'Done' : 'Edit'}
    </button>
  );
};

export default EditButton;
