import React from 'react';

const Rectangle = ({ rectangle, onClick, editMode }) => {
  const [, title] = rectangle.title.split("_");

  // Initialize with a random index from mediaUrls array
  // const randomIndex = rectangle.mediaUrls 
  //   ? Math.floor(Math.random() * rectangle.mediaUrls.length)
  //   : 0;

  // const [currentMediaUrlIndex] = useState(randomIndex);

  // const imageUrl = rectangle.mediaUrls && rectangle.mediaUrls.length > 0
  //   ? rectangle.mediaUrls[currentMediaUrlIndex]
  //   : rectangle.thumbnail;

  const imageUrl = rectangle.thumbnail ? rectangle.thumbnail : rectangle.mediaUrls[0]

  return (
    <div className={`ca-rectangle ${editMode ? 'edit-mode' : ''}`} onClick={onClick}>
      <div className="hover-layer"></div>
      <div className="rectangle-title" style={{ zIndex: 2 }}>
        {editMode ? <span className="edit-mode-title">-</span> : title}
      </div>
      {imageUrl && (
        <div className="image-container">
          <img src={imageUrl} alt="Thumbnail" />
        </div>
      )}
    </div>
  );
};

export default Rectangle;
