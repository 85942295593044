import React, { useState, useEffect } from 'react';
import { ProGallery } from 'pro-gallery';
import PhotoSwipeLightbox from '../Default/photoswipe-lightbox.esm.js';
import PhotoSwipe from '../Default/photoswipe.esm';
import ImageModal from '../Helper/ImageModal.js';
import SectionModal from '../Helper/SectionModal';
import 'pro-gallery/dist/statics/main.css';

const SubsectionPage = ({ user, subsection, options, container, editMode, dbPath, storagePath }) => {
  const [subthumbnailDimensions, setSubThumbnailDimensions] = useState({ width: 0, height: 0 });
  const [subsectionmappedImageArray, setSubsectionMappedImageArray] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSectionModalOpen, setIsSectionModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null); 
  const [editSection, setEditSection] = useState(false);

  const substoragePath = `${storagePath}/${subsection.title}`;
  const subdbPath =`${dbPath}/subsections/${subsection.timestamp}_${subsection.title}`;

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedImage(null);
  }

  const closeSectionModal = () => {
    setEditSection(false);  // Reset editSection to false when modal is closed
    setIsSectionModalOpen(false);
  };

  const openSectionModalForEdit = () => {
    setEditSection(true);  // Set editSection to true for Edit Section
    setIsSectionModalOpen(true);
  };

  useEffect(() => {
    if (subsection.thumbnail) {
      const subthumbnailImage = new Image();
      subthumbnailImage.src = subsection.thumbnail;
      subthumbnailImage.onload = () => {
        setSubThumbnailDimensions({
          width: subthumbnailImage.width,
          height: subthumbnailImage.height,
        });
      };
    }
    const subsectionimageArray = Object.values(subsection);
    const subsectionmappedImageArray = subsectionimageArray
    .filter(({ itemId, mediaUrl, metaData }) => itemId && mediaUrl && metaData)
    .map(({ itemId, mediaUrl, metaData }) => {
      return {
        itemId: itemId,
        mediaUrl: mediaUrl,
        metaData: {
          ...metaData
        }
      };
    });
    setSubsectionMappedImageArray(subsectionmappedImageArray);
  }, [subsection]);

  const psoptions = {
    dataSource: subsectionmappedImageArray.map(({ mediaUrl, metaData }) => {
      const { height, width, title } = metaData;
      return {
        src: mediaUrl,
        width: width,
        height: height,
        alt: title,
      };
    }),
    showHideAnimationType: 'zoom',
    pswpModule: PhotoSwipe
  };
  const lightbox = new PhotoSwipeLightbox(psoptions);

  const thumbnailLightbox = new PhotoSwipeLightbox({
    gallery: '#gallery--getting-started',
    children: 'a',
    pswpModule: PhotoSwipe
  });
  thumbnailLightbox.init();

  const customInfoRenderer = (itemProps) => (
    <div className="hover-info-title" style={{position: 'relative'}}>
      {!editMode && <h5>{itemProps.title}</h5>}
      {editMode && 
        <div 
          style={{
            position: 'absolute', 
            display: 'flex', 
            justifyContent: 'center', 
            alignItems: 'center', 
            color: 'white', 
            fontSize: '4em', 
            cursor: 'pointer'
          }}
        >
          Edit
        </div>
      }
    </div>
  );

  const eventsListener = (eventName, eventData) => {
    if (eventName === 'ITEM_CLICKED') {
      if (editMode) {
        setSelectedImage(subsectionmappedImageArray[eventData.idx]);
        openModal();
      } else {
        if (eventData.type === "video") {
          // Open the video URL in a new tab
          window.open(eventData.url, '_blank');
        } else {
          lightbox.loadAndOpen(eventData.idx);
        }
      }
    }
  };

  return (
    <section id={subsection}>
          <ImageModal
              isModalOpen={isModalOpen}
              closeModal={closeModal}
              storagePath={substoragePath}
              dbPath={subdbPath}
              editImage={!!selectedImage}  // If there's a selected image, we are in edit mode
              imageData={selectedImage}  // Pass selected image data to modal
          />

          <SectionModal 
            isModalOpen={isSectionModalOpen} 
            closeModal={closeSectionModal}
            isSubsection={true}
            sectionData={subsection}
            editSection={editSection}
            dbPath={subdbPath}
            storagePath={substoragePath}
          />
        <div className='subsection-container'>
            <div className='hr-container'>
                <hr/>
            </div>
            <div className="title-container" style={{marginTop: '120px'}}>
                <h1 className="subsection-title">{subsection.title}</h1>
                {user && <button onClick={openSectionModalForEdit} className="effect-button">Edit Section</button>}
                {user && <button onClick={openModal} className="effect-button">Add</button>}
            </div>
            <div className="main-container-dynamic">
                <p className="section-description">{subsection.description}</p>
                <div className="pswp-gallery pswp-gallery--single-column" id="gallery--getting-started">
                <a href={subsection.thumbnail}
                    data-pswp-width={subthumbnailDimensions.width}
                    data-pswp-height={subthumbnailDimensions.height} 
                >
                    {subsection.thumbnail && <img src={subsection.thumbnail} alt={`${subsection.title} Thumbnail`} />}
                </a>
                </div>
                <ProGallery
                domId={subsection.title}
                items={subsectionmappedImageArray}
                options={options}
                container={container}
                eventsListener={eventsListener}
                customHoverRenderer={customInfoRenderer}
                />
            </div>
        </div>
    </section>
  )
}

export default SubsectionPage;
