import React, { useState, useEffect, useContext } from 'react';
import { ProGallery } from 'pro-gallery';
import 'pro-gallery/dist/statics/main.css';
import { useParams } from 'react-router-dom';
import { onAuthStateChanged } from 'firebase/auth'; 
import { auth } from '../../firebase-config';
import PhotoSwipeLightbox from '../Default/photoswipe-lightbox.esm.js';
import PhotoSwipe from '../Default/photoswipe.esm';
import EditModeContext from '../Helper/EditModeContext';
import ImageModal from '../Helper/ImageModal.js';
import { container } from '../Default/ProGalleryConst.js';
import SectionModal from '../Helper/SectionModal';
import { DatabaseContext } from '../Helper/DatabaseProvider.js';
import SubsectionPage from './SubsectionPage';

const options = {
  galleryLayout: 1,
  gallerySize: 25.5,
  imageHoverAnimation: 'ZOOM_IN',
  groupSize: 4,
};

const DynamicPage = () => {
  const { section } = useParams();
  const [user, setUser] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [mappedData, setMappedData] = useState([]);
  const [thumbnail, setThumbnail] = useState(null);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [timestamp, setTimestamp] = useState('');
  const [isSectionModalOpen, setIsSectionModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null); 
  const [thumbnailDimensions, setThumbnailDimensions] = useState({ width: 0, height: 0 });
  const [subsections, setSubsections] = useState([]);
  const [isSubsection, setIsSubsection] = useState(false);
  const [editSection, setEditSection] = useState(false);

  useEffect(() => {
    // Clearing main section data
    setIsModalOpen(false);
    setMappedData([]);
    setThumbnail(null);
    setTitle('');
    setDescription('');
    setTimestamp('');
    setIsSectionModalOpen(false);
    setSelectedImage(null);
    setThumbnailDimensions({ width: 0, height: 0 });
  
    // Clearing subsections data
    setSubsections([]);
  
  }, [section]);

  const dbPath = `images/conceptart/${section}`;
  const storagePath = `images/ConceptArt/${section.split("_")[1]}`;

  const { editMode } = useContext(EditModeContext);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedImage(null);
  }

  const openSectionModal = (forSubsection = false) => {
    setIsSubsection(forSubsection); // set whether it's for a subsection
    setIsSectionModalOpen(true);
  };
  
  // Your existing closeSectionModal function
  const closeSectionModal = () => {
    setIsSubsection(false); // reset it back to false
    setIsSectionModalOpen(false);
  };

  const openSectionModalForEdit = () => {
    setEditSection(true);  // Set editSection to true for Edit Section
    setIsSectionModalOpen(true);
  };

  const images = useContext(DatabaseContext);
  const sectiondata = images ? images.conceptart[section] : undefined;

  useEffect(() => {
    if(sectiondata) {
      if (sectiondata.subsections) {
        const subsectionsArray = Object.values(sectiondata.subsections).map(subsection => {
          const subsectionData = {...subsection};
            return subsectionData;
         });
        setSubsections(subsectionsArray);
      }
      if (sectiondata.thumbnail) {
        setThumbnail(sectiondata.thumbnail);
  
        // Create an Image object to load the thumbnail and get its dimensions
        const thumbnailImage = new Image();
        thumbnailImage.src = sectiondata.thumbnail;
  
        // Listen to the onload event to get the dimensions
        thumbnailImage.onload = () => {
          setThumbnailDimensions({
            width: thumbnailImage.width,
            height: thumbnailImage.height,
          });
        };
      }
      if(sectiondata.title) setTitle(sectiondata.title);
      if(sectiondata.description) setDescription(sectiondata.description);
      if(sectiondata.timestamp) setTimestamp(sectiondata.timestamp)

      const dataArray = Object.values(sectiondata);
        const mappedDataArray = dataArray
          .filter(({ itemId, mediaUrl, metaData }) => itemId && mediaUrl && metaData)
          .map(({ itemId, mediaUrl, metaData }) => {
            return {
              itemId: itemId,
              mediaUrl: mediaUrl,
              metaData: {
                ...metaData
              }
            };
          });
        setMappedData(mappedDataArray);
    }
  }, [sectiondata]);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
    });

    // Clean up subscription
    return () => unsubscribe();
  }, []);

  const customInfoRenderer = (itemProps) => (
    <div className="hover-info-title" style={{position: 'relative'}}>
      {!editMode && <h5>{itemProps.title}</h5>}
      {editMode && 
        <div 
          style={{
            position: 'absolute', 
            display: 'flex', 
            justifyContent: 'center', 
            alignItems: 'center', 
            color: 'white', 
            fontSize: '4em', 
            cursor: 'pointer'
          }}
        >
          Edit
        </div>
      }
    </div>
  );

  const psoptions = {
    dataSource: mappedData.map(({ mediaUrl, metaData }) => {
      const { height, width, title } = metaData;
      return {
        src: mediaUrl,
        width: width,
        height: height,
        alt: title,
      };
    }),
    showHideAnimationType: 'zoom',
    pswpModule: PhotoSwipe
  };
  const lightbox = new PhotoSwipeLightbox(psoptions);

  const thumbnailLightbox = new PhotoSwipeLightbox({
    gallery: '#gallery--getting-started',
    children: 'a',
    pswpModule: PhotoSwipe
  });
  thumbnailLightbox.init();

  const eventsListener = (eventName, eventData) => {
    if (eventName === 'ITEM_CLICKED') {
      if (editMode) {
          setSelectedImage(mappedData[eventData.idx]);
          openModal();
        } else {
        lightbox.loadAndOpen(eventData.idx);
      }
    }
  };

  return (
    <section id={ section }>
      <div className="main-container" style={{marginTop: "120px"}}>
        <h1 className="main-title">{ title }</h1>
        {user && <button onClick={openSectionModalForEdit} className="effect-button">Edit Section</button>}
        {user && <button onClick={openModal} className="effect-button">Add</button>}
      </div>
      
        <ImageModal
          isModalOpen={isModalOpen}
          closeModal={closeModal}
          storagePath={storagePath}
          dbPath={dbPath}
          editImage={!!selectedImage}  // If there's a selected image, we are in edit mode
          imageData={selectedImage}  // Pass selected image data to modal
        />

        <SectionModal 
          isModalOpen={isSectionModalOpen}
          closeModal={closeSectionModal}
          sectionData={sectiondata}
          isSubsection={isSubsection}
          subsection={`${timestamp}_${title}`}
          editSection={editSection}
          dbPath={dbPath}
          storagePath={storagePath}
        />

      <div className="main-container-dynamic">
        <p className="section-description">{ description }</p>
          <div className="pswp-gallery pswp-gallery--single-column" id="gallery--getting-started">
            <a href={thumbnail}
              data-pswp-width={thumbnailDimensions.width}
              data-pswp-height={thumbnailDimensions.height} 
            >
              {thumbnail && <img src={thumbnail} alt="Thumbnail" />}
            </a>
         </div>
         {mappedData.length > 0 && (
          <ProGallery
            domId={section}
            items={mappedData}
            options={options}
            container={container}
            eventsListener={eventsListener}
            customHoverRenderer={customInfoRenderer}
          />
        )}
      </div>
      {subsections.map((subsection, index) => (
          <SubsectionPage 
              key={index}
              user={user}
              subsection={subsection}
              section={section}
              options={options}
              container={container}
              customInfoRenderer={customInfoRenderer}
              editMode={editMode}
              dbPath={dbPath}
              storagePath={storagePath}
            />
        ))}
        <div className="main-container-dynamic">
          {user && <button onClick={() => openSectionModal(true)} className="effect-button" style={{marginTop: '120px'}}>Add Section</button>}
        </div>
    </section>
  );
};

export default DynamicPage;