import React, { useState, useEffect, useContext } from 'react';
// import { useDropzone } from 'react-dropzone';
import { useHistory } from 'react-router-dom';
import { onAuthStateChanged } from 'firebase/auth'; 
import { getStorage, ref, deleteObject } from 'firebase/storage';
import { ref as dbRef, onValue, remove } from 'firebase/database';
import { auth, database } from '../../firebase-config';
import EditModeContext from '../Helper/EditModeContext'
import SectionModal from '../Helper/SectionModal';
import Rectangle from '../Helper/Rectangle';

const ConceptArt = () => {
  const [user, setUser] = useState(null);
  const [rectangles, setRectangles] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const history = useHistory();

  const { editMode } = useContext(EditModeContext);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  useEffect(() => {
    const imageRef = dbRef(database, 'images/conceptart');
    onValue(imageRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        const entries = Object.entries(data);
        const newRectangles = entries.map(([fullTitle, value]) => {
          let mediaUrls = [];
          if (value.subsections) {
            if (fullTitle.includes("Other Projects")) {
              for (const subsection of Object.values(value.subsections)) {
                for (const item of Object.values(subsection)) {
                  if (item.mediaUrl && !item.mediaUrl.includes('.mp4')) {
                    mediaUrls.push(item.mediaUrl);
                  }
                }
              }
            } else {
              for (const subsection of Object.values(value.subsections)) {
                if (subsection.thumbnail && !subsection.thumbnail.includes('.mp4')) {
                  mediaUrls.push(subsection.thumbnail);
                }
              }
            }
          }
          return {
            title: fullTitle,
            order: value.order,
            mediaUrls: mediaUrls.length > 0 ? mediaUrls : null,
            thumbnail: value.thumbnail || null
          };
        });
  
        newRectangles.sort((a, b) => a.order - b.order);
        setRectangles(newRectangles);
      } else {
        setRectangles([]);
      }
    });
    return () => {}; // add unsubscribe logic here if needed
  }, []);
  
  const handleClick = (number) => {
    if (!editMode && number) {
      const newUrl = `/conceptart/${number}`;
      history.push(newUrl);
    } 
  }; 

  const removeRectangleByTitle = (titleToRemove) => {
    setRectangles(rectangles.filter(rectangle => rectangle.title !== titleToRemove));
  };

  const handleDelete = async (fullTitle) => {
    try {
      // Split the title by underscore to get the timestamp and the actual title
      const titleParts = fullTitle.split("_");
      const title = titleParts.slice(1).join("_");
  
      // Use the timestamp and the title to reference the file
      const imageRef = ref(getStorage(), `images/ConceptArt/${title}`);
      
      // Delete the image
      await deleteObject(imageRef);
  
      // Delete the record from the database
      const dbImageRef = dbRef(database, `images/conceptart/${fullTitle}`);
      await remove(dbImageRef);
  
      // Remove the rectangle from the state
      removeRectangleByTitle(fullTitle);
  
      console.log(`Delete ${fullTitle}`);
    } catch (error) {
      console.error(`Failed to delete ${fullTitle}:`, error);
    }
  };  

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
    });

    return () => unsubscribe();
  }, []);

  const renderedRectangles = rectangles.map((rectangle, index) => (
    <Rectangle
      key={rectangle.title}
      rectangle={rectangle}
      onClick={() => (editMode ? handleDelete(rectangle.title) : handleClick(rectangle.title))}
      editMode={editMode}
    />
  ));

  return (
    <section id="conceptArt">
      <div className="title-container">
       <h1 className="main-title">Concept Art</h1>
        {user && <button onClick={openModal} className="effect-button">Add</button>}

        <SectionModal 
          isModalOpen={isModalOpen}
          closeModal={closeModal}
        />
        
      </div>
      <div className="main-container">
        <div className="container">
          {renderedRectangles}
        </div>
      </div>
    </section>
  );
}

export default ConceptArt;
