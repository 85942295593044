import React from 'react';

const Footer = () => {
  return (
    <div className="footer-container">
      <footer className="footer">
        <div className="row">
          <div className="twelve columns">
            <ul className="copyright">
              <li>
                Website design by <a title="Neo" href="http://www.neomaralit.com/">Neo</a>
              </li>
            </ul>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
