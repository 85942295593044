import React, { useState, useEffect, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import Modal from 'react-modal';
import { getStorage, ref, getDownloadURL, uploadBytes, deleteObject } from 'firebase/storage';
import { getDatabase, ref as dbRef, set, update, remove } from 'firebase/database';
import { v4 as uuidv4 } from 'uuid';

const ImageModal = ({isModalOpen, closeModal, storagePath, dbPath, editImage, imageData}) => {
  const [image, setImage] = useState(null);
  const [imageName, setImageName] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [loading, setLoading] = useState(false);

  const onDrop = useCallback(acceptedFiles => {
    setImage(acceptedFiles[0]);
    setImageName(acceptedFiles[0].name);
  }, []);

  const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop});

  const handleTitleChange = (e) => setTitle(e.target.value);
  const handleDescriptionChange = (e) => setDescription(e.target.value);

  const resetForm = () => {
    setTitle('');
    setDescription('');
    setImage(null);
    setImageName('');
    setLoading(false);
  };

  const handleCloseModal = () => {
    if (editImage) resetForm();
    closeModal();
  };

  useEffect(() => {
    if (editImage) {
      setTitle(imageData.metaData.title);
      setDescription(imageData.metaData.description);
      setImageName(imageData.itemId);
    }
  }, [editImage, imageData]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const database = getDatabase();
    const storage = getStorage();

    let  useItemId = uuidv4();  // Generate new ID for new image
    const storageRef = ref(storage, `${storagePath}/${useItemId}`);
    const uploadTask = uploadBytes(storageRef, image);
    
    if (editImage && imageData) {
      let useItemId = imageData.itemId;  // use the old ID if edit mode
      // delete the old image from storage
      const oldStorageRef = ref(storage, `${storagePath}/${useItemId}`);
      await deleteObject(oldStorageRef);
  
      // delete the old object from database
      const oldImageRef = dbRef(database, `${dbPath}/${imageData.metaData.timestamp}_${imageData.metaData.title}`);
      await remove(oldImageRef);
    }
  
    uploadTask.then(async (snapshot) => {
      let downloadURL = await getDownloadURL(storageRef);
      const img = new Image();
      img.onload = async function() {
  
        let imageRef;
        let timestamp = new Date().toISOString();  // create a timestamp string
        timestamp = timestamp.replace(/[:.-]/g, '');  // remove illegal characters
  
        if(editImage && imageData) {
          // Use the original timestamp and title for the imageRef
          imageRef = dbRef(database, `${dbPath}/${imageData.metaData.timestamp}_${title}`);
          timestamp = imageData.metaData.timestamp;
        } else {
          // Create a new db reference
          imageRef = dbRef(database, `${dbPath}/${timestamp}_${title}`);
        }
  
        const newImageData = {
          "itemId": useItemId,
          "mediaUrl": downloadURL,
          "metaData": {
            "type": "image",
            "height": this.height,
            "width": this.width,
            "title": title,
            "description":  description,
            "timestamp": timestamp
          }
        };
  
        if(editImage) {
          await update(imageRef, newImageData);
        } else {
          await set(imageRef, newImageData);
        }
  
        // Reset form fields and close modal
        resetForm();
        closeModal();
      };
      img.src = downloadURL;
    });
  };

  const handleDelete = async () => {
    if (!editImage || !imageData) return;  // Do nothing if not in edit mode or imageData is not present

    setLoading(true);

    const database = getDatabase();
    const storage = getStorage();

    // Delete the image from storage
    const oldStorageRef = ref(storage, `${storagePath}/${imageData.itemId}`);
    await deleteObject(oldStorageRef);

    // Delete the old object from database
    const oldImageRef = dbRef(database, `${dbPath}/${imageData.metaData.timestamp}_${imageData.metaData.title}`);
    await remove(oldImageRef);

    // Reset form fields and close modal
    resetForm();
    closeModal();
  };
  
  return (
    <Modal
      className="effect-image"
      overlayClassName="effect-image-overlay"
      isOpen={isModalOpen}
      onRequestClose={handleCloseModal}
      contentLabel={editImage ? "Edit Image" : "Add Image"}
    >
      <form onSubmit={handleSubmit}>
        <h3 style={{color: 'black'}}>{editImage ? "Edit Image" : "Add Image"}</h3>
        {editImage ? <button type="button" onClick={handleDelete}>Delete</button> : null}  {/* Add this line */}
        <label>
          Title:
          <input 
            type="text" 
            value={title} 
            onChange={handleTitleChange} 
            style={{width: '100%'}}
            placeholder="Title"
          />
        </label>
        <label>
          Description:
          <textarea 
            value={description} 
            onChange={handleDescriptionChange} 
            style={{width: '100%'}}
            placeholder="Description"
          />
        </label>
        <label>
          Media:
          <div {...getRootProps()} style={{
            border: '2px dashed grey',
            padding: '20px',
            background: 'lightgray',
            textAlign: 'center'
          }}>
            <input {...getInputProps()} />
            {isDragActive ? (
              <p>Drop the media here ...</p>
            ) : imageName ? (
              <p>{imageName}</p>
            ) : (
              <p>Drop some media, or click here to select media</p>
            )}
          </div>
        </label>
        <button type="submit">{loading ? 'Loading...' : 'Submit'}</button>
      </form>
    </Modal>
  );
};

export default ImageModal;