import React from 'react';
import { useState } from 'react';
import {
  signInWithEmailAndPassword,
  onAuthStateChanged,
  signOut,
} from "firebase/auth";
import { auth } from "../../firebase-config";

const Login = ({ onSubmit }) => {

  const [loginEmail, setLoginEmail] = useState("");
  const [loginPassword, setLoginPassword] = useState("");

  const [user, setUser] = useState(null);

  onAuthStateChanged(auth, (currentUser) => {
    setUser(currentUser);
  });

  const login = async (event) => {
    event.preventDefault();
    try {
      const user = await signInWithEmailAndPassword(
        auth,
        loginEmail,
        loginPassword
      );
      console.log(user);
    } catch (error) {
      console.log(error.message);
    }
  };

  const logout = async () => {
    await signOut(auth);
  };

  return (
    <form onSubmit={onSubmit}>
      {user ? (
        <>
          <h4> User Logged In: </h4>
          <p>{user.email}</p>
          <button onClick={logout}> Sign Out </button>
        </>
      ) : (
        <>
          <div>
            <label htmlFor="username">Username:</label>
            <input 
              type="text" 
              id="username" 
              name="username"
              placeholder="Email"
              onChange={(event) => {
                setLoginEmail(event.target.value);
              }} 
            />
          </div>
          <div>
            <label htmlFor="password">Password:</label>
            <input 
              type="password" 
              id="password" 
              name="password"
              placeholder="Password"
              onChange={(event) => {
                setLoginPassword(event.target.value);
              }}  
            />
          </div>
          <button type="submit" onClick={login}>Login</button>
        </>
      )}
    </form>
  );
};

export default Login;
