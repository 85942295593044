import React, { Component } from 'react';
import Modal from 'react-modal';
import Login from '../Helper/Login';
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../../firebase-config";
import { getDatabase, ref, onValue } from "firebase/database";
import { Link } from 'react-router-dom';
import { animateScroll as scroll, scroller } from 'react-scroll';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArtstation, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope, faAngleRight, faAngleDown } from '@fortawesome/free-solid-svg-icons';

Modal.setAppElement('#root');

const logo = "https://firebasestorage.googleapis.com/v0/b/chen-portfolio.appspot.com/o/images%2FLogo%2Fstamp_2023.png?alt=media&token=d2bb1b1d-dbde-44c0-8248-92fc732e5e7b";

class Sidebar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loginModalOpen: false,
            user: null,
            conceptArtItems: [],
            isEmailCopied: false,
            openDropdown: null,
        };
        this.scrollToTop = this.scrollToTop.bind(this);
    }

    componentDidMount() {
        onAuthStateChanged(auth, (currentUser) => {
            this.setState({ user: currentUser });
        });

        const db = getDatabase();
        const conceptArtRef = ref(db, 'images/conceptart');

        onValue(conceptArtRef, (snapshot) => {
            const data = snapshot.val();
            if (data) {
                const conceptArtItems = Object.keys(data).map(key => ({
                    title: key,
                    order: data[key].order
                }));

                conceptArtItems.sort((a, b) => a.order - b.order);

                this.setState({ conceptArtItems });
            }
        }, (error) => {
            console.error("Error: " + error.code);
        });
    }

    handleEmailCopy = () => {
        this.setState({ isEmailCopied: true });
        // Set a timeout to hide the message again after 3 seconds
        setTimeout(() => {
            this.setState({ isEmailCopied: false });
        }, 3000);
    };
    
    toggleDropdown = (dropdown) => {
        this.setState((prevState) => ({
            openDropdown: prevState.openDropdown === dropdown ? null : dropdown
        }));
    };    

    scrollToTop() {
      scroll.scrollToTop({
        duration: 1000,
        delay: 0,
        smooth: 'easeInOutQuart',
      });
    }

    scrollTo(pageRef) {
      scroller.scrollTo(pageRef, {
        duration: 1000,
        delay: 0,
        smooth: 'easeInOutQuart',
      });
    } 

    openLoginModal = () => {
        this.setState({ loginModalOpen: true });
    };

    closeLoginModal = () => {
        this.setState({ loginModalOpen: false });
    };

    handleLoginSubmit = (e) => {
        e.preventDefault();
        this.closeLoginModal();
    };

    render() {
        return (
            <div className="area">
                <nav className="main-menu">
                    <ul>
                    <div className="navbar-logo">
                        <Link to="/#home" onClick={this.scrollToTop}>
                            <img className="logo-img1" src={logo} alt="Home" />
                        </Link>
                    </div>
                    <div className="author-name">Olivia Chen</div>
                        <li className="has-subnav">
                            <div className="link-container">
                                <Link to="/#conceptArt" onClick={() => this.scrollTo('conceptArt')}>
                                    <span className="nav-text">Concept Art</span>
                                </Link>
                            </div>
                            <div className="icon-container" onClick={(e) => {
                                e.stopPropagation();
                                this.toggleDropdown('conceptArt');
                            }}>
                                <FontAwesomeIcon
                                    icon={faAngleRight}
                                    size="xl"
                                    className={`icon ${this.state.openDropdown === 'conceptArt' ? 'rotated' : ''}`}
                                    style={{ marginLeft: '10px' }}  // Adding a margin to the left of the icon for spacing
                                />
                            </div>
                            <ul className={`dropdown-content ${this.state.openDropdown === 'conceptArt' ? 'open' : ''}`}>
                                {this.state.conceptArtItems && this.state.conceptArtItems.map((item, index) => (
                                    <li key={item.title}>
                                        <Link to={`/conceptArt/${item.title}`}>
                                            {item.title.split('_').pop()}
                                        </Link>
                                    </li>
                                ))}
                            </ul>
                        </li>
                        <li className="has-subnav">
                            <Link to="/#studies" onClick={() => this.scrollTo('Studies')}>
                                <span className="nav-text">Study</span>
                            </Link>
                        </li>
                        {this.state.user ? (
                            <li className="has-subnav" onClick={this.openLoginModal}>
                                <a href="#">
                                    <span className="nav-text">Account</span>
                                </a>
                            </li>
                        ) : (
                            <li className="has-subnav" onClick={this.openLoginModal}>
                                <a href="#">
                                    <span className="nav-text">Login</span>
                                </a>
                            </li>
                        )}
                        <li className="icon-links">
                            <a href="https://www.artstation.com/oliviachen" target="_blank" rel="noopener noreferrer" className="icon-link">
                                <FontAwesomeIcon icon={faArtstation} size="xl" className="icon" />
                            </a>
                            <a href="https://www.linkedin.com/in/olivia-chen-4833b110a/" target="_blank" rel="noopener noreferrer" className="icon-link">
                                <FontAwesomeIcon icon={faLinkedin} size="xl" className="icon" />
                            </a>
                            <CopyToClipboard text="OChenWorks@gmail.com" onCopy={this.handleEmailCopy} style={{cursor: "pointer"}}>
                                <a className="icon-envelope">
                                    <FontAwesomeIcon icon={faEnvelope} size="xl" className="icon" />
                                    {this.state.isEmailCopied && (
                                        <span className={`copied-message${this.state.isEmailCopied ? ' visible' : ''}`}>
                                            Email copied
                                        </span>
                                    )}
                                </a>
                            </CopyToClipboard>
                        </li>
                    </ul>
                </nav>
                <Modal
                    isOpen={this.state.loginModalOpen}
                    onRequestClose={this.closeLoginModal}
                    contentLabel="Login Modal"
                    className="login-modal"
                    overlayClassName="login-modal-overlay"
                >
                    <h2>Login</h2>
                    <Login onSubmit={this.handleLoginSubmit} />
                    <button onClick={this.closeLoginModal}>Close</button>
                </Modal>
            </div>
        );
    }
}

export default Sidebar;
