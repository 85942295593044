// HamburgerDrawer.js
import React from 'react';
import { Link } from 'react-router-dom';
import { scroller } from 'react-scroll';

const logo = "https://firebasestorage.googleapis.com/v0/b/chen-portfolio.appspot.com/o/images%2FLogo%2Flogo_rednew.png?alt=media&token=3fbcc9cb-0cb8-4c6c-88b8-383b3c5027db";

class HamburgerDrawer extends React.Component {
  scrollTo = (pageRef) => {
    scroller.scrollTo(pageRef, {
      duration: 1000,
      delay: 0,
      smooth: 'easeInOutQuart',
    });
  };

  render() {
    return (
      <div className="header-container">
        <div className="logo-h2-container">
          <div className="logo-container">
            <img src={logo} alt="Logo" />
          </div>
          <h2>Olivia Chen</h2>
        </div>
        <nav role="navigation" className="hamburger-drawer">
          <div id="menuToggle">
            <input type="checkbox" />
            <span></span>
            <span></span>
            <span></span>
            <ul id="menu">
              <Link to="/#home" onClick={() => this.scrollTo('home')}><li>Home</li></Link>
              <Link to="/#conceptArt" onClick={() => this.scrollTo('conceptArt')}><li>Concept Art</li></Link>
              <Link to="/#studies" onClick={() => this.scrollTo('Studies')}><li>Studies</li></Link>
              {/* <li onClick={this.props.openLoginModal}>Login</li> */}
              {/* ... other menu items ... */}
            </ul>
          </div>
        </nav>
      </div>
    );
  }
}

export default HamburgerDrawer;