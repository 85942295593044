import React, { useState, useEffect, useContext } from 'react';
import Sidebar from './Components/Categories/Sidebar';
import HamburgerDrawer from './Components/Helper/HamburgerDrawer';
import ConceptArt from './Components/Categories/conceptArt';
import DynamicPage from './Components/Categories/DynamicPage';
import EditButton from './Components/Helper/EditButton';
import Footer from './Components/Categories/Footer';

import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import ScrollToTop from './Components/Default/ScrollToTop';
import { EditModeProvider } from './Components/Helper/EditModeContext';
import { DatabaseContextProvider, DatabaseContext } from './Components/Helper/DatabaseProvider'; // Adjust this import according to your project structure

import { onAuthStateChanged } from 'firebase/auth';
import { auth } from './firebase-config'; 

const App = () => {
  const [editMode, setEditMode] = useState(false);
  const [user, setUser] = useState(null);

  const toggleEditMode = () => setEditMode(prev => !prev);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, setUser);
    return () => unsubscribe();
  }, []);

  const images = useContext(DatabaseContext);

  return (
    <div className="App">
      <Router>
        <ScrollToTop />
        <Sidebar/>
        <HamburgerDrawer />
        <DatabaseContextProvider value={{ images }}>
          <Switch>
            <Route exact path="/">
              {/* <Header /> */}
                <EditModeProvider value={{editMode, toggleEditMode}}>
                  {user && <EditButton />} 
                  <div className="main-content">
                  <ConceptArt />
                  <Footer />
                  </div>
                </EditModeProvider>
            </Route>
            <Route path="/conceptart/:section">
              <EditModeProvider value={{editMode, toggleEditMode}}>
                {user && <EditButton />}
                <div className="main-content">
                <DynamicPage />
                <Footer />
                </div>
              </EditModeProvider>
            </Route>
          </Switch>
        </DatabaseContextProvider>
      </Router>
    </div>
  );
};

export default App;