import React, { createContext, useState, useEffect } from 'react';
import { ref, onValue } from "firebase/database";
import { database } from '../../firebase-config';

export const DatabaseContext = createContext();

export const DatabaseContextProvider = (props) => {
  const [images, setImages] = useState();

  useEffect(() => {
    const imagesRef = ref(database, 'images');
    onValue(imagesRef, (snapshot) => {
      const imagesData = snapshot.val();
      setImages(imagesData);
    });
  }, []);

  return (
    <DatabaseContext.Provider value={images}>
      {props.children}
    </DatabaseContext.Provider>
  );
}
