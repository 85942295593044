const getContainerDimensions = () => {
  const windowWidth = window.innerWidth;
  const windowHeight = window.innerHeight;

  return {
    width: windowWidth < 992 ? windowWidth : windowWidth - 900,
    height: windowHeight,
  };
};

export const container = getContainerDimensions();
