import React, { useState, useCallback, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import Modal from 'react-modal';
import { getStorage, ref, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage';
import { ref as dbRef, set, get, update } from 'firebase/database';
import { database } from '../../firebase-config';

const SectionModal = ({ isModalOpen, closeModal, isSubsection, subsection, sectionData, editSection, dbPath, storagePath }) => {
  const [image, setImage] = useState(null);
  const [imageName, setImageName] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (editSection) {
      setTitle(sectionData.title);
      setDescription(sectionData.description);
      if (sectionData.thumbnail) {
        setImageName(sectionData.title + "_thumbnail");
      }
    }
  }, [isModalOpen, sectionData, editSection]); 
  

  const handleCloseModal = () => {
    if (editSection) resetForm();
    closeModal();
  };

  const onDrop = useCallback(acceptedFiles => {
    setImage(acceptedFiles[0]); // Set the first selected file
    setImageName(acceptedFiles[0].name); // Set the image name
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const handleTitleChange = (e) => setTitle(e.target.value);
  const handleDescriptionChange = (e) => setDescription(e.target.value);

  const resetForm = () => {
    setTitle('');
    setDescription('');
    setImage(null);
    setImageName('');
    setLoading(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
  
    // Delete the old thumbnail if a new image is provided
    if (editSection && sectionData.thumbnail && image) {
      const oldStorageRef = ref(getStorage(), `${storagePath}/${title}_thumbnail`);
      await deleteObject(oldStorageRef);
    }
  
    let sectionCount = 0;
    const sectionRef = dbRef(database, 'images/conceptart');
    const snapshot = await get(sectionRef);
    if (snapshot.exists()) {
      sectionCount = (Object.keys(snapshot.val()).length) - 1;
    }
  
    let timestamp = new Date().toISOString();
    timestamp = timestamp.replace(/[:.-]/g, '');
  
    // Preserve old thumbnail URL if not updated
    let thumbnailUrl = sectionData ? sectionData.thumbnail : null;
  
    // Upload new image if provided
    if (image) {
      // Set the reference based on the isSubsection value
      const storageRef = ref(
        getStorage(),
        editSection
          ? `${storagePath}/${title}_thumbnail`
          : isSubsection
            ? `images/ConceptArt/${subsection.split("_")[1]}/${title}/${title}_thumbnail`
            : `images/ConceptArt/${title}/${title}_thumbnail`
      );      
  
      const uploadSnapshot = await uploadBytes(storageRef, image);
      thumbnailUrl = await getDownloadURL(uploadSnapshot.ref);
    }

    let updateData;

    if (editSection) {
      updateData = {
        title: title,
        description: description,
        thumbnail: thumbnailUrl,
        order: sectionData.order
      };
    } else {
      updateData = {
        title: title,
        description: description,
        thumbnail: thumbnailUrl,
        timestamp: timestamp,
        order: sectionCount
      };
    }
  
    const targetRef = dbRef(database, editSection
      ? `${dbPath}`
      : isSubsection
        ? `images/conceptart/${subsection}/subsections/${timestamp}_${title}`
        : `images/conceptart/${timestamp}_${title}`);

  // Use update if editSection is true, else use set
  if (editSection) {
    await update(targetRef, updateData);  // <--- Use update here
  } else {
    await set(targetRef, updateData);
  }
  
    resetForm();
    closeModal();
  };

  const hideDescriptionAndImageDropFields = title.toLowerCase() === 'projects';

  return (
    <Modal
      className="add-section-modal"
      overlayClassName="add-section-overlay"
      isOpen={isModalOpen}
      onRequestClose={handleCloseModal}
      contentLabel={editSection ? "Edit Section" : "Add Section"}
    >
      <form onSubmit={handleSubmit}>
        <h3 style={{ color: 'black' }}>{editSection ? 'Edit Section' : 'Add Section'}</h3>
        <label>
          Title:
          <input
            type="text"
            value={title}
            onChange={handleTitleChange}
            required
            style={{ width: '100%' }}
            placeholder="Title"
          />
        </label>
        {!hideDescriptionAndImageDropFields && (
          <>
            <label>
              Description:
              <textarea
                value={description}
                onChange={handleDescriptionChange}
                style={{ width: '100%' }}
                placeholder="Description"
              />
            </label>
            <label>
              Image:
              <div
                {...getRootProps()}
                style={{
                  border: '2px dashed grey',
                  padding: '20px',
                  background: 'lightgray',
                  textAlign: 'center'
                }}
              >
                <input {...getInputProps()} />
                {isDragActive ? (
                  <p>Drop the image here ...</p>
                ) : imageName ? (
                  <p>{imageName}</p>
                ) : (
                  <p>Drop some image, or click here to select image</p>
                )}
              </div>
            </label>
          </>
        )}
        <button type="submit">{loading ? 'Loading...' : 'Submit'}</button>
      </form>
    </Modal>
  );
}

export default SectionModal;